import { useRouter } from 'next/router';
import React, { memo } from 'react';
import Header from '@components/molecules/Header';
import { ContainerProps } from 'types/Basic-ContainerTypes';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SplitIo } from '@utils/splitIo.enum';
import Footer from '@components/molecules/Footer/Footer';
import MandiNewsFooter from '@components/molecules/Footer/MandiNewsFooter';
import { getAuthData } from '@utils/storage';
import useHomepageExperiment from 'hook/useHomepageExperiment';
import PullToRefreshContainer from '@components/Container/pull-to-refresh-container';

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const {
      headerTitle,
      backUrl,
      isBackButton,
      children,
      childClassName,
      showDownload,
      showNotification,
      showHelp,
      downloadAction,
      className,
      leftIcon,
      showFooter,
      titleClassNames,
      onBack,
      showQr,
      partyQrData,
      isNew = false,
      banner,
      bannerShow,
      pullTorefresh,
      handleRefresh,
      triggerStoriesPopup,
      ...rest
    } = props;

    const treatments = useTreatments([
      SplitIo.ScanPay,
      SplitIo.HomeIconFooter,
      SplitIo.TransactionHpFooter,
      SplitIo.LoansHpFooter,
      SplitIo.PartiesHpFooter,
      SplitIo.CommerceHpFooter,
      SplitIo.NFCCommerce,
    ]);

    const { showMandiNewsAsHomePage } = useHomepageExperiment();

    const router = useRouter();
    const userId = getAuthData()?.userId;

    const onBackClick = () => {
      onBack?.();
      if (backUrl) {
        router.push(backUrl);
      }
    };

    return (
      <div
        className="flex-col nc-webkit-noscrollbar tw-flex tw-h-full"
        {...rest}
        ref={ref}
      >
        <Header
          className={className}
          downloadAction={downloadAction}
          headerTitle={headerTitle}
          isBackButton={isBackButton}
          isNew={isNew}
          leftIcon={leftIcon}
          partyQrData={partyQrData}
          showDownload={showDownload}
          showHelp={showHelp}
          showNotification={showNotification}
          showQr={showQr && treatments[SplitIo.ScanPay].treatment === 'on'}
          titleClassNames={titleClassNames}
          onBackClick={onBackClick}
        />
        <div
          className={`tw-flex tw-flex-col tw-p-2 ${
            childClassName || ''
          } tw-basis-full`}
          style={{
            paddingTop: isNew ? '65px' : '52px',
            ...(showFooter ? { paddingBottom: '70px' } : {}),
          }}
        >
          {pullTorefresh ? (
            <PullToRefreshContainer onPullToRefresh={handleRefresh}>
              <>
                {bannerShow && (
                  <div
                    className="tw-mt-[-10px]"
                    style={{ position: 'sticky', top: '60px', left: 0 }}
                  >
                    <img
                      alt={'ninjamandi'}
                      className="tw-h-[184px] tw-w-full"
                      src={banner}
                      onClick={triggerStoriesPopup}
                    />
                  </div>
                )}
                {children}
              </>
            </PullToRefreshContainer>
          ) : (
            <>
              {bannerShow && (
                <div
                  className="tw-mt-[-10px]"
                  style={{ position: 'sticky', top: '60px', left: 0 }}
                >
                  <img
                    alt={'ninjamandi'}
                    className="tw-h-[184px] tw-w-full"
                    src={banner}
                    onClick={triggerStoriesPopup}
                  />
                </div>
              )}
              {children}
            </>
          )}
        </div>
        {showFooter &&
          (userId ? (
            showMandiNewsAsHomePage ? (
              <MandiNewsFooter />
            ) : (
              <Footer />
            )
          ) : (
            <Footer />
          ))}
      </div>
    );
  }
);

export default memo(Container);
