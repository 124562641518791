import Image from 'next/image';
import * as React from 'react';
import { MenusType } from 'utils/types/commontypes';
import { NCText } from '@ninjacart/nc-components-ui';
import useEvents from 'hook/useEvents';
import { TraderEventType } from '@utils/eventsConstant';

interface PropTypes {
  redirectToMenu: Function;
  item: MenusType;
}
export const HamburgerItem = React.memo((props: PropTypes) => {
  const { triggerEvent } = useEvents();
  return (
    <div
      className="items-center tw-flex tw-flex-row tw-gap-4 tw-pt-[10px] tw-pr-[6px] "
      onClick={() => {
        if (props.item.trackedEventName) {
          triggerEvent(TraderEventType.Generic_event, {
            eventName: props.item.trackedEventName,
          });
        }

        props.redirectToMenu(props.item?.route);
      }}
    >
      <div className="-tw-mt-2">
        <Image alt="" height={22} src={props.item?.icon} width={22} />
      </div>
      <NCText
        isBlock
        color="#1f1f21"
        tailwindClasses="!tw-text-[15px] tw-border-b border-b tw-border-solid border-slate-70	pb-4 tw-flex-1 tw-ml-2 tw-pl-2"
        weight="400"
      >
        {props.item?.name}
      </NCText>
    </div>
  );
});

export const HamburgerItemNew = (props: PropTypes) => {
  const { triggerEvent } = useEvents();
  return (
    <div
      className=" tw-mt-[30px] tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2"
      onClick={() => {
        if (props?.item?.trackedEventName) {
          triggerEvent(TraderEventType.Generic_event, {
            eventName: props?.item?.trackedEventName,
          });
        }

        props.redirectToMenu(props?.item?.route);
      }}
    >
      <div className="tw-justify-center">
        <Image alt="" height={24} src={props?.item?.icon} width={24} />
      </div>
      <NCText
        isBlock
        tailwindClasses="bodyText !tw-text-blackText !tw-font-medium tw-justify-center tw-items-center tw-flex-1 tw-ml-2"
        weight="500"
      >
        {props?.item?.name}
      </NCText>
    </div>
  );
};
