import { NCIcon, NCText } from '@ninjacart/nc-components-ui';
import * as React from 'react';
import { ROUTE_NAMES } from 'utils/routes';
import { redirectToPage } from 'utils/nativeUtils';
import { useRouter } from 'next/router';
import {
  hamburgerMenu,
  hamburgerStaffMenu,
  hamburgerMiscellaneousMenu,
} from 'utils/lists';
import { HamburgerItem } from '@components/molecules/HamburgerItem';
import { TraderEventType } from 'utils/eventsConstant';
import useEvents from 'hook/useEvents';
import { iconSize } from 'constants/common';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SplitIo } from 'utils/splitIo.enum';
import useStorage from 'hook/useStorage';
import GenericAccordion from '@components/molecules/GenericAccordion';
import { STORAGE_KEYS } from '@utils/keys';
import { strings } from 'constants/translations';

interface PropTypes {
  toggleHamburger: Function;
  isHamburgerOpen?: boolean;
  handleLogout: () => void;
}

function Hamburger(props: PropTypes) {
  const { toggleHamburger, isHamburgerOpen } = props;
  const { getItem } = useStorage();
  const realmOwnerData = getItem(STORAGE_KEYS.REALM_OWNER_DATA);
  const { role } = realmOwnerData || {};
  const isRoleOwner = role === 'OWNER';
  const { triggerEvent } = useEvents();
  const router = useRouter();

  const treatments = useTreatments([
    SplitIo.SoundboxSettings,
    SplitIo.NotificationHamburger,
    SplitIo.AddMoney,
    SplitIo.AddSellingTrade,
    SplitIo.Rewards,
    SplitIo.Offers,
    SplitIo.ReferAndEarn,
    SplitIo.Check_Credit_Score,
  ]);

  const redirectToMenu = (url: string) => {
    const parsedUrl = url.split('?')[0];
    if (ROUTE_NAMES.LOGOUT === url) {
      props.handleLogout();
      return;
    }

    if (url === 'app/content/select-party-new') {
      const additionalDetails = [
        {
          refType: 'ContractFlowType',
          refValue: 'SimplifiedSellingTrade',
        },
        { refType: 'CTAPlacement', refValue: 'TraderHomepage_Offerings' },
      ];
      if (getItem('Collect Assure')) {
        additionalDetails.push({
          refType: 'UserPlanStatus',
          refValue: getItem('Collect Assure'),
        });
      }
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'AddContractInitiate',
        eventType: 'UserAction',
        additionalDetails,
      });

      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'Choose_party_initiate',
      });
    }

    if (
      [
        ROUTE_NAMES.HELP,
        ROUTE_NAMES.LANGUAGE,
        ROUTE_NAMES.SWITCH_BUSINESS,
        ROUTE_NAMES.PRIVACY_POLICY,
        ROUTE_NAMES.TERMSCONDITION,
        ROUTE_NAMES.NOTIFICAION,
        ROUTE_NAMES.PROFILE,
      ].includes(parsedUrl)
    ) {
      router.push(url);
      return;
    }

    redirectToPage(url);
  };

  const hamburgerItemList = React.useMemo(() => {
    const hamburgerList: any[] = [];

    hamburgerList.push(
      hamburgerMenu().filter((item) => {
        if (item.key) {
          return treatments[item.key]?.treatment === 'on';
        }
        return true;
      })
    );
    return hamburgerList.flat();
  }, [treatments]);

  const hamburgerStaffItemList = React.useMemo(() => {
    const staffList: any[] = [];

    staffList.push(
      hamburgerStaffMenu().filter((item) => {
        if (item.key) {
          return treatments[item.key]?.treatment === 'on';
        }
        return true;
      })
    );
    return staffList.flat();
  }, [treatments]);

  const miscellaneousItemList = React.useMemo(() => {
    const miscellaneousItems: any[] = [];
    miscellaneousItems.push(
      hamburgerMiscellaneousMenu().filter((item) => {
        if (item.key) {
          return treatments[item.key]?.treatment === 'on';
        }
        return true;
      })
    );
    return miscellaneousItems.flat();
  }, [treatments]);

  return (
    <>
      <div
        className={`z-20 tw-fixed tw-top-0 tw-z-[10] tw-z-20 tw-h-screen tw-w-full ${
          !isHamburgerOpen && 'tw--left-full'
        }  tw-bg-black/[0.7]`}
        onClick={() => toggleHamburger(false)}
      />
      <div
        aria-labelledby="drawer-label"
        className={`tw-fixed tw-top-0 ${
          isHamburgerOpen ? 'tw-left-0' : 'tw--left-full'
        } tw-z-40 tw-h-screen tw-w-10/12 tw-overflow-hidden tw-overflow-y-auto tw-bg-white tw-transition-all tw-duration-300  tw-ease-linear`}
        id="drawer-example"
      >
        <div
          className="px-4 tw-flex tw-h-14 tw-content-between tw-items-center tw-bg-[#60A744]"
          role="button"
          tabIndex={-1}
          onClick={() => {
            triggerEvent(TraderEventType.Generic_event, {
              eventName: TraderEventType.Menu_select,
            });
            toggleHamburger(false);
          }}
        >
          <div className="t-w-[40px]">
            <NCIcon
              color="white"
              height={iconSize}
              iconName="hamburger"
              width={25}
            />
          </div>
          <div className="tw-flex tw-flex-1 tw-pl-6">
            <NCText className={'tw-text-xl tw-font-medium'} color="white">
              {strings.hello}
            </NCText>
          </div>
        </div>
        <div className="nc-webkit-noscrollbar tw-border-b-1 border-solid border-b-1 px-3 tw-mx-auto tw-flex tw-h-5/6 tw-flex-col tw-overflow-auto tw-border-solid tw-px-3 ">
          {(!isRoleOwner ? hamburgerStaffItemList : hamburgerItemList).map(
            (menuItem) => (
              <HamburgerItem
                key={menuItem.menuName}
                item={menuItem}
                redirectToMenu={redirectToMenu}
              />
            )
          )}
          {isRoleOwner && (
            <GenericAccordion
              title="Miscellaneous"
              titleClass="tw-text-[#008000]"
            >
              <div className="nc-webkit-noscrollbar tw-border-b-1 border-solid border-b-1 px-3 tw-mx-auto tw-flex tw-h-5/6 tw-flex-col tw-overflow-auto tw-border-solid tw-px-3 ">
                {miscellaneousItemList.map((menuItem) => (
                  <HamburgerItem
                    key={menuItem.menuName}
                    item={menuItem}
                    redirectToMenu={redirectToMenu}
                  />
                ))}
              </div>
            </GenericAccordion>
          )}
        </div>
        <div className="tw-absolute tw-bottom-0 tw-h-10 tw-w-full tw-bg-[#60A744]" />
      </div>
    </>
  );
}

export default Hamburger;
