import { ISvgProps } from '../../../types/generic.types';

const ArrowUpIcon = (props: ISvgProps) => {
  const { width, height, color } = props;
  return (
    <svg
      fill={color ? color : '#000'}
      height={height}
      viewBox="0 96 960 960"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M283 712.566 226.434 656 480 402.434 733.566 656 677 712.566l-197-197-197 197Z" />
    </svg>
  );
};

export default ArrowUpIcon;
