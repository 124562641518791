import { ISvgProps } from '../../../types/generic.types';

const ArrowDownIcon = (props: ISvgProps) => {
  const { width, height, color } = props;
  return (
    <svg
      fill={color ? color : '#000'}
      height={height}
      viewBox="0 96 960 960"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M480 724.001 226.434 470.435 283 413.869l197 198 197-197 56.566 56.566L480 724.001Z" />
    </svg>
  );
};

export default ArrowDownIcon;
