import { STORAGE_KEYS } from '@utils/keys';
import { getStorageItem } from '@utils/storage';
import { ReactElement, memo } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';

export interface IProps {
  children: ReactElement;
  onPullToRefresh: () => Promise<void>;
}

const PullToRefreshContainer = (props: IProps) => {
  const { children, onPullToRefresh } = props;
  const isNew = getStorageItem(STORAGE_KEYS.NEW_DASHBOARD);

  return (
    <PullToRefresh
      pullDownThreshold={95}
      pullingContent={
        <div
          className={`w-full text-white text-center text-sm p-5 ${
            isNew ? 'tw-bg-ninja-primary-600' : 'bg-primary'
          }`}
        >
          Pull To Refresh
        </div>
      }
      resistance={3}
      onRefresh={onPullToRefresh}
    >
      <div className="h-[93vh]">{children}</div>
    </PullToRefresh>
  );
};

export default memo(PullToRefreshContainer);
