import BasicContainer from '@components/Container/basic-container';
import { NCIcon } from '@ninjacart/nc-components-ui';
import Hamburger from '@components/Container/hamburger-menu';

import { useGlobalContext } from '../contexts/index';
//dummy comment
export default function Home() {
  const { toggleHamBurger, isHamburgerOpen } = useGlobalContext();
  return (
    <BasicContainer
      showNotification
      headerTitle="Hello!"
      leftIcon={
        <div
          role="button"
          tabIndex={0}
          onClick={() => toggleHamBurger(!isHamburgerOpen)}
        >
          <NCIcon color="white" height={20} iconName="hamburger" width={20} />
        </div>
      }
    >
      {isHamburgerOpen && <Hamburger toggleHamburger={toggleHamBurger} />}
    </BasicContainer>
  );
}
