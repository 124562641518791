import { ReactNode, useState } from 'react';
import ArrowUpIcon from '../../../assets/icons/ArrowUp';
import ArrowDownIcon from '../../../assets/icons/ArrowDown';

interface IProps {
  title: string;
  children: ReactNode;
  titleClass?: string;
}

const GenericAccordion = (props: IProps) => {
  const { title, children, titleClass = '' } = props;

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((current) => !current);

  const titleClasses = `tw-flex-1 tw-text-lg ${titleClass}`;
  const childWrapperClasses = `tw-px-2 tw-overflow-hidden ${
    expanded ? 'tw-max-h-auto' : 'tw-max-h-0'
  }`;

  return (
    <div className="tw-my-2 tw-w-full tw-bg-white">
      <div
        className="tw-flex tw-select-none tw-items-center tw-justify-between tw-p-2 tw-text-left"
        onClick={toggleExpanded}
      >
        <span className={titleClasses}>{title}</span>
        <div className="tw-flex-none tw-pl-2">
          {expanded ? (
            <ArrowUpIcon color="gray" height={20} width={20} />
          ) : (
            <ArrowDownIcon color="gray" height={20} width={20} />
          )}
        </div>
      </div>
      <div className={childWrapperClasses}>{children}</div>
    </div>
  );
};

export default GenericAccordion;
